<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Roles</v-card-title>
        <v-btn class=" my-auto primary" @click="addRole()">Add role</v-btn>
      </div>
    </v-card>
    <v-card class="ma-2">
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="dataList"
            :items-per-page="5"
            hide-default-footer
        >
          <!-- <template v-slot:item.actions="{item}">
            <v-btn icon color="error" v-if="!item.isDeleted">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template> -->

          <template v-slot:item.keyword="{item}">
            <router-link :to="{name: 'roleView', params: {id: item.id}}">{{ item.keyword }}</router-link>
            <!--          <div>{{item.keyword}}</div>-->
          </template>

          <template v-slot:item.actions="{item}">
            <v-icon @click="openEditRole(item.id)"
                    class="cursor-pointer"
                    style="color: #397dfb"
            >
              mdi-pencil
            </v-icon>
            <v-icon @click="toggleActivation(item.id)"
                    class="cursor-pointer mx-7"
                    style="color: #ff9800"
            >
              {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
            </v-icon>
            <v-icon @click="openPermissionsModal(item.id)"
                    class="cursor-pointer mr-4"
            >
              mdi-account-star
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>


    <v-dialog
        v-model="permissionsDialog"
        max-width="500px"

    >
      <v-card>
        <v-card-title>
          <span class="headline">Set Permissions</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col fluid
                     cols="12"
                     sm="6"
                     md="6"
                     v-for="item in permissionsList" :key="item.id"
              >
                <v-checkbox
                    v-model="permissionIds"
                    :label="item.keyword"
                    :value="item.id"
                    hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                  color="error"
                  style="width: 100%"
                  @click="permissionsDialog = false"
              >
                close
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  style="width: 100%"
                  @click="setPermissions()"
              >
                save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--  modals-->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Edit role</span>
          <span v-else class="headline">Add role</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    v-model="role.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Role Name"
                    outlined
                    v-model="role.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn @click="dialog = false" style="width: 100%" color="error">
                close
              </v-btn>
            </v-col>
            <v-col v-if="update">
              <v-btn @click="saveRole(role.id)" style="width: 100%" color="warning">
                edit
              </v-btn>
            </v-col>
            <v-col v-else>
              <v-btn @click="addnewRole()" style="width: 100%" color="success">
                save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "RoleList",

  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Keyword', value: 'keyword', align: 'center', sortable: false},
        {text: 'Name', value: 'name', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      dataList: [],
      permissionsList: [],
      permissionIds: [],
      role: {
        id: 0,
        keyword: '',
        name: '',
        permissionIds: [],
        // selectedRoleId = 0
      },
      permissionsDialog: false,
      dialog: false,
      update: false
    }
  },
  methods: {
    addRole() {
      this.dialog = true,
          this.update = false

      this.role.keyword = ''
      this.role.name = ''
    },
    async addnewRole() {
      try {
        const res = await this.$http.post(`Role/upsertRole`, this.role);
        if (!res.error) {
          // console.log(res.result.roles);
          this.dataList = res.result.roles;
          this.dialog = false
          this.successNotification()
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async openEditRole(id) {
      await this.getRoles(id);
      this.dialog = true
      this.update = true

    },
    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Role/toggleRoleActivation?roleId=${id}`);
        if (!res.error) {
          // console.log(res.result)
          this.successNotification();
          this.dataList = res.result.roles
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveRole() {
      this.showLoader();
      try {
        const res = await this.$http.post('Role/upsertRole', this.role);
        if (!res.error) {
          // console.log(res.result.role)
          this.dataList = res.result.roles;
          this.dialog = false;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getRoles(id) {
      await this.$http.get(`Role/getRole?roleId=${id}`).then(res => {
        if (!res.error) {
          // console.log(res.result)
          this.role = res.result
        }
      }).catch(e => {
        this.errorNotification(e.response.data.message)
      })
    },
// accaunt-star- icon
    async getPermissions() {
      // this.showLoader();
      try {
        const res = await this.$http.get('Permission/getPermissions');
        // console.log(res.result.permissions);
        this.permissionsList = res.result.permissions
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },

    async openPermissionsModal(id) {
      await this.getRoles(id);
      await this.getPermissions()
      this.permissionIds = this.role.permissionIds
      this.permissionsDialog = true
      // this.selectedRoleId = id;
    },
    setPermissions() {
      this.role.permissionIds = this.permissionIds
      this.saveRole(this.role.id)
      this.permissionsDialog = false;
    },

    async getRole() {
      try {
        const res = await this.$http.get('Role/getRoles')
        this.dataList = res.result.roles
        // console.log(res.result);
      } catch (e) {
        this.errorNotification(e.response.data.error.message)
      }
    }
  },
  created() {
    this.getRole()
    // this.$http.get('Role/getRoles') .then((res) => {
    //   // console.log(res.result)
    //   this.dataList = res.result.roles
    // })
    //   .catch((e) => {
    //   console.log(e)
    // });
    this.getPermissions()
  }
}
</script>

<style scoped>

</style>
